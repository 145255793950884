import {inject, Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {SocialUser} from "@abacritt/angularx-social-login";
import {AuthData, AuthorInterface, SocialIdentity} from "./user";
import {UserLogin} from "../core/auth/login/types/user-login";
import {UserRegister} from "../core/auth/register/types/user-register";
import {PasswordResetRequest} from "../core/auth/password-reset-request/types/password-reset-request";
import {Observable} from "rxjs";
import {PasswordReset} from "../core/auth/password-reset/types/password-reset";
import {User} from "./user";

@Injectable({providedIn: 'root'})
export class UserService {
  private _httpClient = inject(HttpClient)

  public loginWithProvider(user: SocialUser) {
    return this._httpClient.post('/api/v2/auth/' + user.provider.toLowerCase(), user)
  }

  getSocialIdentities() {
    return this._httpClient.get<SocialIdentity[]>('/api/v2/auth/social-identities')
  }

  getIdentity() {
    return this._httpClient.get<AuthData>('/api/v2/session/info', { withCredentials: true })
  }

  login(credentials: UserLogin) {
    return this._httpClient.post<any>('/api/v2/session', credentials)
  }

  logout() {
    return this._httpClient.delete<any>('/api/v2/session')
  }

  register(data: UserRegister) {
    return this._httpClient.post<any>('/api/v2/users', data)
  }

  passwordResetRequest(data: PasswordResetRequest): Observable<any> {
    return this._httpClient.post<any>(`/api/v2/users/password-reset`, data)
  }

  passwordReset(token: string, data: PasswordReset): Observable<any> {
    return this._httpClient.post<any>(`/api/v2/users/password-reset/${token}`, data)
  }

  passwordChange(id: string, data: { currentPassword: string, password: string, passwordRepeat: string }): Observable<any> {
    return this._httpClient.post<any>(`/api/v1/users/${id}/password-change`, data)
  }

  changeAvatar(user: User|AuthorInterface, file: File): Observable<any> {
    const body = new FormData();
    body.append('avatar', file);
    return this._httpClient.post<any>(`/api/v1/users/${user._id}/avatar`, body)
  }

  deleteAvatar(user: User): Observable<any> {
    return this._httpClient.delete<any>(`/api/v1/users/${user._id}/avatar`)
  }

  updateProfile(id: string, data: any) {
    return this._httpClient.put<any>(`/api/v1/users/${id}/profile`, data)
  }

  getProfile(slug: string) {
    return this._httpClient.get<User>(`/api/v1/users/${slug}`)
  }

  unlinkSocialIdentity(provider: string) {
    return this._httpClient.delete<any>(`/api/v2/auth/${provider.toLowerCase()}/unlink`)
  }

  connectSocialIdentity(provider: string, user: SocialUser) {
    return this._httpClient.post<any>(`/api/v2/auth/${provider.toLowerCase()}/link`, user)
  }
}
